import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import ChartPage from "./pages/ChartPage";
import ErrorBoundary from "./components/ErrorBoundary";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <React.Fragment>
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />}>
              {protectedRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={<ErrorBoundary>{route.element}</ErrorBoundary>}
                    key={index}
                  />
                )
              })}
            </Route>
            {publicRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={<ErrorBoundary>{route.element}</ErrorBoundary>}
                  key={index}
                />
              )
            })}
            <Route path="chart" element={<ErrorBoundary><ChartPage /></ErrorBoundary>} />
            <Route path="*" element={<ErrorBoundary><NotFound /></ErrorBoundary>} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </React.Fragment>
  );
}
