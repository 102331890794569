import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import ReactApexChart from "react-apexcharts";
import './StrategyDashboard.css'; // We'll update this CSS file for custom styles

const StrategyCard = ({ title, data }) => (
  <div className="styled-card strategy-card">
    <h3>{title}</h3>
    <ul className="list-unstyled">
      {Object.entries(data).map(([key, value]) => (
        <li key={key} className="d-flex justify-content-between">
          <span>{key}</span>
          <span className="fw-bold">{value}</span>
        </li>
      ))}
    </ul>
  </div>
);

const ParameterSlider = ({ label, min, max, value, unit, onChange }) => (
  <div className="slider-container">
    <label className="slider-label">{label}</label>
    <input
      type="range"
      className="styled-slider"
      min={min}
      max={max}
      value={value}
      onChange={(e) => onChange(parseInt(e.target.value, 10))}
    />
    <div className="slider-values">
      <small>{min}{unit}</small>
      <small>{value}{unit}</small>
      <small>{max}{unit}</small>
    </div>
  </div>
);

const MetricCard = ({ title, value, subValue, change, changeDescription }) => (
  <div className="styled-card metric-card">
    <h3>{title}</h3>
    <h2 className="mb-0">{value}</h2>
    <p className="text-muted">{subValue}</p>
    <p className={`mb-0 ${change.startsWith('+') ? 'text-success' : 'text-danger'}`}>
      {change}
    </p>
    <small className="text-muted">{changeDescription}</small>
  </div>
);

const useChartOptions = (theme) => {
  return useMemo(() => ({
    chart: {
      type: 'area',
      height: 300,
      toolbar: { show: false },
      background: 'transparent'
    },
    colors: ['#3498db', '#2ecc71'],
    stroke: { curve: 'smooth', width: 3 },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0, 90, 100]
      }
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      labels: { style: { colors: theme === 'dark' ? '#ffffff' : '#333333' } }
    },
    yaxis: {
      labels: { style: { colors: theme === 'dark' ? '#ffffff' : '#333333' } }
    },
    tooltip: {
      theme: theme
    }
  }), [theme]);
};

const useDashboardData = () => {
  const [data, setData] = useState({
    occupancyRate: 85,
    averageDailyRate: 120,
    revenueForecast: [
      { name: 'Actual', data: [30, 40, 35, 50, 49, 60, 70, 91, 125] },
      { name: 'Forecast', data: [35, 45, 40, 55, 54, 65, 75, 96, 130] }
    ]
  });

  // Simulating data fetch
  useEffect(() => {
    // In a real app, you'd fetch data from an API here
    // For now, we'll just use the initial state
  }, []);

  return data;
};

export default function StrategyDashboard() {
  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || 'light');
  const [viewMode, setViewMode] = useState('grid');
  const chartOptions = useChartOptions(skin);
  const dashboardData = useDashboardData();

  const switchSkin = useCallback((skin) => {
    // Implementation remains the same
  }, []);

  useEffect(() => {
    switchSkin(skin);
  }, [skin, switchSkin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Strategy Optimizer</li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Strategy Optimizer</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0 align-items-center">
            <span>Grid</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={viewMode === 'list'}
                onChange={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
              />
              <span className="switch"></span>
            </label>
            <span>List</span>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate Report
            </Button>
          </div>
        </div>

        <div className={`content-container ${viewMode}`}>
          <div className="styled-card" style={{ gridColumn: '1 / -1' }}>
            <h5 className="card-title">Revenue Forecast</h5>
            <ReactApexChart options={chartOptions} series={dashboardData.revenueForecast} type="area" height={300} />
          </div>
          
          <MetricCard
            title="Occupancy Rate"
            value={`${dashboardData.occupancyRate}%`}
            subValue="Current occupancy rate"
            change="+5%"
            changeDescription="vs. previous period"
          />
          
          <MetricCard
            title="Average Daily Rate"
            value={`$${dashboardData.averageDailyRate}`}
            subValue="Current ADR"
            change="+$10"
            changeDescription="vs. previous period"
          />

          <StrategyCard 
            title="Current strategy" 
            data={{
              Occupancy: '85%',
              ADR: '$340',
              RevPAR: '$297',
              'Total Rev': '$1.78M'
            }}
          />

          <StrategyCard 
            title="Conservative" 
            data={{
              Occupancy: '89%',
              ADR: '$348',
              RevPAR: '$310',
              'Total Rev': '$1.85M'
            }}
          />

          <StrategyCard 
            title="Dynamic" 
            data={{
              Occupancy: '96%',
              ADR: '$367',
              RevPAR: '$352',
              'Total Rev': '$2.1M'
            }}
          />

          <div className="styled-card" style={{ gridColumn: '1 / -1' }}>
            <h5 className="card-title">Parameters</h5>
            <ParameterSlider label="Room Rate Standard" min={100} max={299} value={120} unit="$" onChange={() => {}} />
            <ParameterSlider label="Room Rate Suite" min={300} max={1000} value={400} unit="$" onChange={() => {}} />
            <ParameterSlider label="Overbooking limits" min={0} max={20} value={5} unit="%" onChange={() => {}} />
            <ParameterSlider label="Minimum length of stay" min={1} max={30} value={1} unit=" night" onChange={() => {}} />
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}