import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const LandingPage = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h1 className="main-title">The operational Insight's Platform</h1>
            <p className="lead">OpsMetrics gives you the insight and confidence to go after opportunities and grow your business, all from one integrated platform.</p>
          </div>
        </div>

        <Row className="g-3 mb-3">
          <Col md={6}>
            <Card className="card-one h-100">
              <Card.Body className="d-flex flex-column">
                <h5 className="card-title">Seamless Data Integration &amp; Orchestration</h5>
                <p>Interactive Data Visualization &amp; Reporting</p>
                <p>Automate Workflows, Maximize Efficiency</p>
                <Button variant="primary" className="mt-auto">Integrate Datasets</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-one h-100">
              <Card.Body className="d-flex flex-column">
                <h5 className="card-title">Engage and Convert Every Client</h5>
                <p>Deliver personalized experiences that delight your customers and boost your revenue</p>
                <Button variant="primary" className="mt-auto">Learn More</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col md={6}>
            <Card className="card-one h-100">
              <Card.Body className="d-flex flex-column">
                <h5 className="card-title">Unified Marketing, Sales, and Revenue Operations</h5>
                <p>Marketing Data</p>
                <p>Revenue Data</p>
                <p>Sales Data</p>
                <Button variant="primary" className="mt-auto">Explore Features</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-one h-100">
              <Card.Body className="d-flex flex-column">
                <h5 className="card-title">Accelerate Your Opportunities</h5>
                <p>Maximize your revenue potential by align your sales, marketing, and revenue operations</p>
                <Button variant="primary" className="mt-auto">Get Started</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card className="card-one">
          <Card.Body>
            <h5 className="card-title">Flexible Pricing Tailored to Your Needs</h5>
            <Row className="g-3 mt-2">
              <Col md={4}>
                <Card className="card-pricing">
                  <Card.Body>
                    <h6>Growth</h6>
                    <h2>$250<small>/mo</small></h2>
                    <ul className="list-unstyled">
                      <li>5 users</li>
                      <li>Basic reporting</li>
                      <li>API access</li>
                      <li>Email support</li>
                    </ul>
                    <Button variant="outline-primary" className="w-100">Choose Plan</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="card-pricing card-pricing-popular">
                  <Card.Body>
                    <h6>Pro</h6>
                    <h2>$750<small>/mo</small></h2>
                    <ul className="list-unstyled">
                      <li>Unlimited users</li>
                      <li>Advanced analytics</li>
                      <li>API access</li>
                      <li>24/7 phone &amp; email support</li>
                    </ul>
                    <Button variant="primary" className="w-100">Choose Plan</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="card-pricing">
                  <Card.Body>
                    <h6>Enterprise</h6>
                    <h2>$2,500<small>/mo</small></h2>
                    <ul className="list-unstyled">
                      <li>Unlimited users</li>
                      <li>Custom reporting</li>
                      <li>Dedicated account manager</li>
                      <li>24/7 premium support</li>
                    </ul>
                    <Button variant="outline-primary" className="w-100">Choose Plan</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default LandingPage;