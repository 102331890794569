import React, { useState } from 'react';
import { Card, Row, Col, Table, Form, Button, Nav, Dropdown, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AttributionOverview = () => {
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState(new Date());
  const [chartType, setChartType] = useState('bar');

  const attributionData = [
    { type: 'Sales - Meeting', preOpp: '$1,060,532', postOpp: '$1,211,125', total: '$2,271,657', opps: '118', touches: '166', companies: '115', people: '166' },
    { type: 'Drift Chat', preOpp: '$893,910', postOpp: '$338,971', total: '$1,232,881', opps: '69', touches: '70', companies: '68', people: '69' },
    { type: 'Content (Gated)', preOpp: '$978,298', postOpp: '$244,951', total: '$1,223,249', opps: '76', touches: '80', companies: '75', people: '77' },
  ];

  const chartOptions = {
    chart: {
      type: chartType,
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      categories: ['2023-01-01', '2023-02-01', '2023-03-01', '2023-04-01', '2023-05-01', '2023-06-01', '2023-07-01'],
    },
    yaxis: {
      title: {
        text: 'Attribution Value ($)'
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    },
    fill: {
      opacity: 1
    },
    colors: ['#506fd9', '#85b6ff', '#4f6fd9', '#c1ccf1', '#e5e9f2', '#cde1ff'],
    tooltip: {
      y: {
        formatter: function (val) {
          return "$" + val.toLocaleString();
        }
      }
    },
  };

  const series = [
    {
      name: 'Sales - Meeting',
      data: [30000, 40000, 35000, 50000, 49000, 60000, 70000]
    },
    {
      name: 'Drift Chat',
      data: [20000, 30000, 25000, 40000, 39000, 50000, 60000]
    },
    {
      name: 'Content (Gated)',
      data: [10000, 20000, 15000, 30000, 29000, 40000, 50000]
    }
  ];

  const forecastChartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      },
    },
    stroke: {
      curve: 'smooth',
      width: [4, 4, 4],
      dashArray: [0, 0, 3]
    },
    xaxis: {
      type: 'category',
      categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
      title: {
        text: 'Attribution Value ($)'
      },
    },
    colors: ['#506fd9', '#85b6ff', '#4f6fd9'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$" + val.toLocaleString();
        }
      }
    },
    markers: {
      size: 5,
      hover: {
        size: 7
      }
    },
  };

  const forecastSeries = [
    { name: 'Actual', data: [310000, 400000, 280000, 510000, 420000, 490000] },
    { name: 'Forecast', data: [450000, 520000, 380000, 240000, 330000, 390000] },
    { name: 'Previous Year', data: [290000, 350000, 260000, 470000, 380000, 450000] }
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Attribution Overview</li>
            </ol>
            <h4 className="main-title mb-0">Attribution Overview</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <InputGroup>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="form-control"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="form-control"
              />
            </InputGroup>
            <Button variant="primary">Apply</Button>
          </div>
        </div>

        <Row className="g-3 mb-4">
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title text-uppercase fs-12 mb-3">Pre-Opp Attribution</h6>
                <h2 className="card-value mb-1">$5.84M</h2>
                <p className="fs-12 text-success mb-0">+2.5% from last period</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title text-uppercase fs-12 mb-3">Post-Opp Attribution</h6>
                <h2 className="card-value mb-1">$2.55M</h2>
                <p className="fs-12 text-danger mb-0">-1.8% from last period</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title text-uppercase fs-12 mb-3">Total Attribution</h6>
                <h2 className="card-value mb-1">$8.39M</h2>
                <p className="fs-12 text-success mb-0">+0.6% from last period</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title text-uppercase fs-12 mb-3">Total Pipeline</h6>
                <h2 className="card-value mb-1">$8.82M</h2>
                <p className="fs-12 text-success mb-0">+3.2% from last period</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card className="card-one mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title as="h6">Attribution Over Time by Campaign Type</Card.Title>
            <div className="d-flex gap-2">
              <Dropdown>
                <Dropdown.Toggle variant="white" size="sm">
                  {chartType.charAt(0).toUpperCase() + chartType.slice(1)} Chart
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setChartType('bar')}>Bar Chart</Dropdown.Item>
                  <Dropdown.Item onClick={() => setChartType('line')}>Line Chart</Dropdown.Item>
                  <Dropdown.Item onClick={() => setChartType('area')}>Area Chart</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button variant="white" size="sm">
                <i className="ri-download-cloud-line me-1"></i> Export Data
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactApexChart options={chartOptions} series={series} type={chartType} height={400} />
          </Card.Body>
        </Card>

        <Card className="card-one mb-4">
          <Card.Header>
            <Card.Title as="h6">Attribution Forecast</Card.Title>
          </Card.Header>
          <Card.Body>
            <ReactApexChart 
              options={forecastChartOptions}
              series={forecastSeries}
              type="line" 
              height={350} 
            />
          </Card.Body>
        </Card>

        <Card className="card-one">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title as="h6">Attribution by Campaign Type</Card.Title>
            <div>
              <Button variant="white" size="sm" className="me-2">
                <i className="ri-list-check me-1"></i> Columns
              </Button>
              <Button variant="white" size="sm" className="me-2">
                <i className="ri-filter-3-line me-1"></i> Filters
              </Button>
              <Button variant="white" size="sm">
                <i className="ri-download-cloud-line me-1"></i> Export
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive hover className="table-striped">
              <thead>
                <tr>
                  <th>Group By Campaign Type</th>
                  <th>Pre-Opp Attribution</th>
                  <th>Post-Opp Attribution</th>
                  <th>Total Attribution</th>
                  <th>Opps Attributed</th>
                  <th>Touches Attributed</th>
                  <th>Companies</th>
                  <th>People</th>
                </tr>
              </thead>
              <tbody>
                {attributionData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.type}</td>
                    <td>{item.preOpp}</td>
                    <td>{item.postOpp}</td>
                    <td>{item.total}</td>
                    <td>{item.opps}</td>
                    <td>{item.touches}</td>
                    <td>{item.companies}</td>
                    <td>{item.people}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AttributionOverview;