import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Nav, Table, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const APIMonitoring = () => {
  const [timePeriod, setTimePeriod] = useState('Last 24 hours');
  const [activeTab, setActiveTab] = useState('general');

  const standardColors = ['#506fd9', '#85b6ff', '#4f6fd9', '#c1ccf1', '#e5e9f2', '#cde1ff'];

  // Timeline Chart
  const timelineChartOptions = {
    chart: {
      height: 350,
      type: 'rangeBar'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%'
      }
    },
    xaxis: {
      type: 'datetime'
    },
    stroke: {
      width: 1
    },
    fill: {
      type: 'solid',
      opacity: 0.6
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    colors: ['#00E396', '#FF4560']
  };

  const timelineChartSeries = [
    {
      name: 'Uptime',
      data: [
        {
          x: 'Salesforce',
          y: [new Date().getTime() - 24 * 60 * 60 * 1000, new Date().getTime()]
        },
        {
          x: 'Marketo',
          y: [new Date().getTime() - 24 * 60 * 60 * 1000, new Date().getTime()]
        },
        {
          x: 'RingLead',
          y: [new Date().getTime() - 24 * 60 * 60 * 1000, new Date().getTime()]
        },
        {
          x: 'Google Analytics',
          y: [new Date().getTime() - 24 * 60 * 60 * 1000, new Date().getTime()]
        }
      ]
    },
    {
      name: 'Downtime',
      data: [
        {
          x: 'Salesforce',
          y: [new Date().getTime() - 22 * 60 * 60 * 1000, new Date().getTime() - 21.5 * 60 * 60 * 1000]
        },
        {
          x: 'Marketo',
          y: [new Date().getTime() - 18 * 60 * 60 * 1000, new Date().getTime() - 17.8 * 60 * 60 * 1000]
        }
      ]
    }
  ];

  // Response Time Chart
  const responseTimeChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Salesforce', 'Marketo', 'RingLead', 'Google Analytics'],
    },
    yaxis: {
      title: {
        text: 'ms'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " ms"
        }
      }
    },
    colors: standardColors
  };

  const responseTimeChartSeries = [
    {
      name: 'Average Response Time',
      data: [320, 250, 180, 90]
    }
  ];

  // Error Rate Chart
  const errorRateChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    xaxis: {
      categories: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'],
    },
    yaxis: {
      title: {
        text: 'Error Rate (%)'
      },
      min: 0,
      max: 5
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(2) + "%"
        }
      }
    },
    legend: {
      position: 'top'
    },
    colors: standardColors
  };

  const errorRateChartSeries = [
    {
      name: 'Salesforce',
      data: [1.2, 0.8, 1.5, 2.1, 1.8, 1.3]
    },
    {
      name: 'Marketo',
      data: [0.9, 1.2, 1.8, 1.5, 1.1, 0.7]
    },
    {
      name: 'RingLead',
      data: [0.5, 0.7, 0.9, 0.8, 0.6, 0.4]
    },
    {
      name: 'Google Analytics',
      data: [0.3, 0.5, 0.6, 0.4, 0.3, 0.2]
    }
  ];

  const apiHealthData = [
    { name: 'Salesforce API', checks: 144, lastCheck: '2 minutes ago', uptime: 99.8 },
    { name: 'Marketo API', checks: 144, lastCheck: '2 minutes ago', uptime: 99.9 },
    { name: 'RingLead API', checks: 144, lastCheck: '2 minutes ago', uptime: 100 },
    { name: 'Google Analytics API', checks: 144, lastCheck: '2 minutes ago', uptime: 100 },
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">API Monitoring</li>
            </ol>
            <h3 className="main-title mb-0">Operational Systems API Monitoring</h3>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Form.Select 
              className="w-auto" 
              value={timePeriod} 
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <option>Last hour</option>
              <option>Last 24 hours</option>
              <option>Last 7 days</option>
              <option>Last 30 days</option>
            </Form.Select>
          </div>
        </div>

        <Row className="g-3 mb-3">
          <Col md={12}>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">API Uptime/Downtime</Card.Title>
              </Card.Header>
              <Card.Body>
                <ReactApexChart options={timelineChartOptions} series={timelineChartSeries} type="rangeBar" height={200} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title mb-3">Salesforce API</h6>
                <h3 className="card-value">320 ms</h3>
                <small>Average Response Time</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title mb-3">Marketo API</h6>
                <h3 className="card-value">250 ms</h3>
                <small>Average Response Time</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title mb-3">RingLead API</h6>
                <h3 className="card-value">180 ms</h3>
                <small>Average Response Time</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h6 className="card-title mb-3">Google Analytics API</h6>
                <h3 className="card-value">90 ms</h3>
                <small>Average Response Time</small>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card className="card-one">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="general" onSelect={(k) => setActiveTab(k)}>
              <Nav.Item>
                <Nav.Link eventKey="general">General</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="performance">Performance</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="errors">Errors</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alerts">Alerts</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            {activeTab === 'general' && (
              <div>
                <h6 className="mb-4">API Health Overview</h6>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>API</th>
                      <th>Checks</th>
                      <th>Last Check</th>
                      <th>24H Uptime</th>
                      <th>7D Uptime</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiHealthData.map((api, index) => (
                      <tr key={index}>
                        <td>{api.name}</td>
                        <td>{api.checks} checks</td>
                        <td>{api.lastCheck}</td>
                        <td>
                          <ProgressBar now={api.uptime} label={`${api.uptime}%`} variant={api.uptime === 100 ? "success" : api.uptime >= 99 ? "warning" : "danger"} />
                        </td>
                        <td>
                          <ProgressBar now={api.uptime} label={`${api.uptime}%`} variant={api.uptime === 100 ? "success" : api.uptime >= 99 ? "warning" : "danger"} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            {activeTab === 'performance' && (
              <div>
                <h6 className="mb-4">API Response Times</h6>
                <ReactApexChart options={responseTimeChartOptions} series={responseTimeChartSeries} type="bar" height={350} />
              </div>
            )}
            {activeTab === 'errors' && (
              <div>
                <h6 className="mb-4">Error Rates</h6>
                <ReactApexChart options={errorRateChartOptions} series={errorRateChartSeries} type="line" height={350} />
              </div>
            )}
            {activeTab === 'alerts' && (
              <div>
                <h6 className="mb-4">Recent Alerts</h6>
                <ul>
                  <li>Salesforce API response time exceeded threshold (350ms) at 14:30</li>
                  <li>Marketo API experienced 2 minutes of downtime at 08:15</li>
                  <li>RingLead API error rate spiked to 3% at 10:45</li>
                  <li>Google Analytics API performance degraded (150ms response time) at 16:20</li>
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default APIMonitoring;