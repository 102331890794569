import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const RecommendationCard = ({ title, description, impact, tags, type }) => (
  <Card className="h-100">
    <Card.Body>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="card-value">+{impact}%</h3>
        {tags.map((tag, index) => (
          <span key={index} className="badge bg-light text-dark me-1">{tag}</span>
        ))}
      </div>
      <Card.Title>{title}</Card.Title>
      <Card.Text>{description}</Card.Text>
      <Button variant="outline-primary" size="sm">View recommendation</Button>
    </Card.Body>
  </Card>
);

const AIRecommendations = () => {
  const recommendations = [
    {
      title: 'Implement Account-Based Marketing',
      description: 'Focus on high-value target accounts by developing personalized marketing campaigns and sales approaches tailored to each account\'s specific needs and pain points.',
      impact: 15,
      tags: ['Marketing', 'Sales'],
      type: 'revenue'
    },
    {
      title: 'Enhance Customer Success Program',
      description: 'Develop a robust customer success program to improve retention, reduce churn, and increase upsell opportunities. Implement regular check-ins, personalized training, and proactive support.',
      impact: 12,
      tags: ['Customer Retention', 'Upsell'],
      type: 'customer'
    },
    {
      title: 'Optimize Product Onboarding',
      description: 'Streamline the onboarding process for new customers to reduce time-to-value. Create interactive tutorials, provide personalized onboarding sessions, and develop a knowledge base for self-service.',
      impact: 10,
      tags: ['Product', 'Customer Experience'],
      type: 'product'
    },
    {
      title: 'Expand Partner Ecosystem',
      description: 'Develop strategic partnerships with complementary B2B tech companies to expand market reach, enhance product offerings, and create new revenue streams through integrations and co-marketing initiatives.',
      impact: 18,
      tags: ['Partnerships', 'Growth'],
      type: 'growth'
    },
    {
      title: 'Implement AI-Driven Lead Scoring',
      description: 'Utilize AI and machine learning algorithms to analyze historical data and predict which leads are most likely to convert, allowing sales teams to focus their efforts on high-potential opportunities.',
      impact: 14,
      tags: ['Sales', 'AI'],
      type: 'sales'
    },
    {
      title: 'Develop Thought Leadership Content',
      description: 'Create high-quality, industry-specific content such as whitepapers, webinars, and case studies to position your company as a thought leader and attract potential clients in the B2B tech space.',
      impact: 9,
      tags: ['Marketing', 'Brand'],
      type: 'marketing'
    }
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">AI Recommendations</li>
            </ol>
            <h4 className="main-title mb-0">AI Recommendations</h4>
          </div>
        </div>

        <Card className="card-one mb-4">
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h2 className="card-value mb-0">78%</h2>
              <div style={{ width: '100px', height: '100px', border: '10px solid #28a745', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span className="fs-2">78%</span>
              </div>
            </div>
            <h6 className="card-title mb-1">Choose strategy or adjust parameters manually</h6>
            <p className="mb-0">Your strategy is focused on mid-sized B2B tech companies in the SaaS sector.</p>
            <p className="mb-0">Consider diversifying your product offerings to capture a wider market share.</p>
            <p className="mb-0">Improve customer retention strategies to increase lifetime value and reduce churn.</p>
          </Card.Body>
        </Card>

        <div className="mb-4">
          <span className="fs-sm me-2">Recommendations to improve:</span>
          {['Revenue', 'Customer Retention', 'Product', 'Growth', 'Marketing'].map((tag, index) => (
            <span key={index} className="badge bg-light text-dark me-1">{tag}</span>
          ))}
        </div>

        <Row className="g-3">
          {recommendations.map((rec, index) => (
            <Col key={index} md={6} lg={4}>
              <RecommendationCard {...rec} />
            </Col>
          ))}
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AIRecommendations;