import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Table, Nav, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const MarketingMixModeling = () => {
  const [timePeriod, setTimePeriod] = useState('Last 12 months');
  const [activeTab, setActiveTab] = useState('overview');
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [budgetAllocations, setBudgetAllocations] = useState({
    'PPC Generic': 1000000,
    'PLA': 800000,
    'TV': 2000000,
    'Press': 1500000,
    'Radio': 500000,
    'Display': 700000,
    'Facebook': 400000
  });

  const channelPerformance = [
    { channel: 'PPC Generic', spend: 1000000, revenue: 5100000, roi: 5.1 },
    { channel: 'PLA', spend: 800000, revenue: 2880000, roi: 3.6 },
    { channel: 'TV', spend: 2000000, revenue: 5600000, roi: 2.8 },
    { channel: 'Press', spend: 1500000, revenue: 3150000, roi: 2.1 },
    { channel: 'Radio', spend: 500000, revenue: 800000, roi: 1.6 },
    { channel: 'Display', spend: 700000, revenue: 980000, roi: 1.4 },
    { channel: 'Facebook', spend: 400000, revenue: 40000, roi: 0.1 },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Marketing Efficiency Ratio (MER) is calculated by dividing total revenue by total marketing spend.
    </Tooltip>
  );

  const standardColors = ['#506fd9', '#85b6ff', '#4f6fd9', '#c1ccf1', '#e5e9f2', '#cde1ff'];

  // Overview Chart
  const overviewChartOptions = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: channelPerformance.map(item => item.channel),
    },
    yaxis: {
      title: {
        text: '$ (millions)'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " millions"
        }
      }
    },
    colors: standardColors
  };

  const overviewChartSeries = [
    {
      name: 'Spend',
      data: channelPerformance.map(item => item.spend / 1000000)
    },
    {
      name: 'Revenue',
      data: channelPerformance.map(item => item.revenue / 1000000)
    }
  ];

  // Channel Analysis Chart
  const channelAnalysisChartOptions = {
    chart: {
      type: 'scatter',
      height: 350,
      zoom: {
        enabled: true,
        type: 'xy'
      }
    },
    xaxis: {
      title: {
        text: 'Spend (millions)'
      },
      tickAmount: 10,
    },
    yaxis: {
      title: {
        text: 'ROI'
      },
      tickAmount: 7,
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const data = channelPerformance[dataPointIndex];
        return '<div class="arrow_box">' +
          '<span><strong>' + data.channel + '</strong></span><br/>' +
          '<span>Spend: $' + (data.spend / 1000000).toFixed(2) + 'M</span><br/>' +
          '<span>ROI: ' + data.roi.toFixed(2) + '</span>' +
          '</div>'
      }
    },
    colors: standardColors
  };

  const channelAnalysisChartSeries = [{
    name: "Channels",
    data: channelPerformance.map(item => [item.spend / 1000000, item.roi])
  }];

  // Forecasting Chart
  const forecastingChartOptions = {
    chart: {
      type: 'line',
      height: 350
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Revenue (millions)'
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " millions"
        }
      }
    },
    legend: {
      position: 'top'
    },
    colors: standardColors
  };

  const forecastingChartSeries = [
    {
      name: "Actual Revenue",
      data: [12, 15, 18, 22, 26, 28, 30, 32, 35, 37, 39, 42]
    },
    {
      name: "Forecasted Revenue",
      data: [12, 15, 18, 22, 26, 28, 31, 34, 38, 42, 46, 50]
    }
  ];

  // Competitor Analysis Chart
  const competitorAnalysisChartOptions = {
    chart: {
      type: 'radar',
      height: 350
    },
    xaxis: {
      categories: ['PPC', 'Social Media', 'Email', 'Display', 'TV', 'Radio']
    },
    yaxis: {
      show: false
    },
    markers: {
      size: 4,
      hover: {
        size: 6
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val.toFixed(2)
        }
      }
    },
    colors: standardColors
  };

  const competitorAnalysisChartSeries = [
    {
      name: 'Our Company',
      data: [4.2, 3.5, 4.8, 3.2, 3.9, 3.1]
    },
    {
      name: 'Competitor A',
      data: [3.8, 4.1, 3.9, 3.7, 3.3, 3.5]
    },
    {
      name: 'Competitor B',
      data: [3.5, 4.3, 4.1, 3.9, 3.2, 3.8]
    }
  ];

  const handleBudgetChange = (channel, value) => {
    setBudgetAllocations({
      ...budgetAllocations,
      [channel]: parseInt(value)
    });
  };

  const simulateBudgetImpact = () => {
    let totalRevenue = 0;
    let totalSpend = 0;
    
    Object.keys(budgetAllocations).forEach(channel => {
      const channelData = channelPerformance.find(c => c.channel === channel);
      if (channelData) {
        const ratio = budgetAllocations[channel] / channelData.spend;
        totalRevenue += channelData.revenue * ratio;
        totalSpend += budgetAllocations[channel];
      }
    });

    const newROI = totalRevenue / totalSpend;
    return { totalRevenue, totalSpend, newROI };
  };

  const { totalRevenue, totalSpend, newROI } = simulateBudgetImpact();

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Marketing Mix Modeling</li>
            </ol>
            <h3 className="main-title mb-0">Marketing Mix Modeling</h3>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Form.Select 
              className="w-auto" 
              value={timePeriod} 
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <option>Last 3 months</option>
              <option>Last 6 months</option>
              <option>Last 12 months</option>
              <option>Year to date</option>
            </Form.Select>
          </div>
        </div>

        <Row className="g-3 mb-3">
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h5 className="card-title">Total Marketing Spend</h5>
                <h3 className="card-value">${totalSpend.toLocaleString()}</h3>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h5 className="card-title">Total Revenue</h5>
                <h3 className="card-value">${totalRevenue.toLocaleString()}</h3>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h5 className="card-title">Overall ROI</h5>
                <h3 className="card-value">{newROI.toFixed(2)}</h3>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card-one">
              <Card.Body>
                <h5 className="card-title">
                  MER
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <i className="ri-information-line ms-1"></i>
                  </OverlayTrigger>
                </h5>
                <h3 className="card-value">{newROI.toFixed(2)}</h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card className="card-one">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="overview" onSelect={(k) => setActiveTab(k)}>
              <Nav.Item>
                <Nav.Link eventKey="overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="channelAnalysis">Channel Analysis</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="forecasting">Forecasting</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="competitorAnalysis">Competitor Analysis</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            {activeTab === 'overview' && (
              <ReactApexChart options={overviewChartOptions} series={overviewChartSeries} type="bar" height={350} />
            )}
            {activeTab === 'channelAnalysis' && (
              <ReactApexChart options={channelAnalysisChartOptions} series={channelAnalysisChartSeries} type="scatter" height={350} />
            )}
            {activeTab === 'forecasting' && (
              <ReactApexChart options={forecastingChartOptions} series={forecastingChartSeries} type="line" height={350} />
            )}
            {activeTab === 'competitorAnalysis' && (
              <ReactApexChart options={competitorAnalysisChartOptions} series={competitorAnalysisChartSeries} type="radar" height={350} />
            )}
          </Card.Body>
        </Card>

        <Card className="card-one mt-3">
          <Card.Header>
            <Card.Title as="h5">Interactive Budget Allocation Tool</Card.Title>
          </Card.Header>
          <Card.Body>
            <p>Use this tool to simulate different budget allocations and see their potential impact on ROI.</p>
            <Button variant="primary" onClick={() => setShowBudgetModal(true)}>Launch Budget Allocation Simulator</Button>
          </Card.Body>
        </Card>

        <Card className="card-one mt-3">
          <Card.Header>
            <Card.Title as="h5">What-If Scenario Analysis</Card.Title>
          </Card.Header>
          <Card.Body>
            <p>Explore different scenarios to understand potential outcomes:</p>
            <ul>
              <li>What if we increase PPC Generic budget by 20%?</li>
              <li>What if we decrease Facebook ad spend by 50%?</li>
              <li>What if we redistribute TV budget to Digital channels?</li>
            </ul>
            <Button variant="outline-primary">Run Scenario Analysis</Button>
          </Card.Body>
        </Card>

        <Card className="card-one mt-3">
          <Card.Header>
            <Card.Title as="h5">Insights and Recommendations</Card.Title>
          </Card.Header>
          <Card.Body>
            <ul>
              <li><strong>PPC Generic:</strong> Consistently shows the highest ROI (5.1). Action: Increase budget allocation by 15-20% and monitor performance closely.</li>
              <li><strong>Facebook ads:</strong> Severely underperforming with ROI of 0.1. Action: Conduct a thorough audit of campaigns, targeting, and creatives. Consider pausing underperforming ads and reallocating budget to top-performing channels.</li>
              <li><strong>TV and Press:</strong> Good ROI (2.8 and 2.1 respectively) but may be nearing diminishing returns. Action: Optimize ad placements and timing. Consider A/B testing different creative approaches to improve efficiency.</li>
              <li><strong>Display and Radio:</strong> Moderate ROI (1.4 and 1.6). Action: Refine targeting strategies and explore new ad formats. Conduct audience analysis to identify high-value segments.</li>
              <li><strong>Seasonality:</strong> Channel performance varies significantly throughout the year. Action: Develop a dynamic budget allocation strategy that adjusts spend based on seasonal trends.</li>
              <li><strong>Competitor Analysis:</strong> Our company outperforms competitors in PPC and Email marketing. Action: Document and share best practices from these channels across other marketing teams.</li>
            </ul>
          </Card.Body>
        </Card>

        <div className="mt-3">
          <Button variant="primary" className="me-2">Export Full Report</Button>
          <Button variant="outline-primary">Schedule Weekly Report</Button>
        </div>

        <Modal show={showBudgetModal} onHide={() => setShowBudgetModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Budget Allocation Simulator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {Object.keys(budgetAllocations).map(channel => (
                <Form.Group key={channel} className="mb-3">
                  <Form.Label>{channel}</Form.Label>
                  <Form.Control 
                    type="number" 
                    value={budgetAllocations[channel]} 
                    onChange={(e) => handleBudgetChange(channel, e.target.value)}
                  />
                </Form.Group>
              ))}
            </Form>
            <div className="mt-3">
              <h5>Simulated Results:</h5>
              <p>Total Spend: ${totalSpend.toLocaleString()}</p>
              <p>Projected Revenue: ${totalRevenue.toLocaleString()}</p>
              <p>Projected ROI: {newROI.toFixed(2)}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowBudgetModal(false)}>Close</Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default MarketingMixModeling;
