const dashboardMenu = [
  {
    "label": "Analytics",
    "icon": "ri-dashboard-line",
    "submenu": [
      {
        "label": "Strategy Optimizer",
        "link": "/dashboard/strategy",
        "icon": "ri-line-chart-line"
      },
      {
        "label": "AI Recommendations",
        "link": "/dashboard/ai-recommendations",
        "icon": "ri-robot-line"
      },
      {
        "label": "Attribution Overview",
        "link": "/dashboard/attribution-overview",
        "icon": "ri-pie-chart-line"
      },
      {
        "label": "Marketing Mix Modeling",
        "link": "/dashboard/marketing-mix-modeling",
        "icon": "ri-bubble-chart-line"
      },
      {
        "label": "Campaign Performance",
        "link": "/dashboard/campaign-performance",
        "icon": "ri-focus-3-line"
      },
      {
        "label": "Website Analytics",
        "link": "/dashboard/analytics",
        "icon": "ri-bar-chart-2-line"
      }
    ]
  },
  {
    "label": "Data Management",
    "icon": "ri-database-2-line",
    "submenu": [
      {
        "label": "Data Sources",
        "link": "/dashboard/data-sources",
        "icon": "ri-database-2-line"
      },
      {
        "label": "Storage Management",
        "link": "/dashboard/storage",
        "icon": "ri-hard-drive-2-line"
      },
      {
        "label": "API Monitoring",
        "link": "/dashboard/api-monitoring",
        "icon": "ri-radar-line"
      }
    ]
  },
  {
    "label": "Operations",
    "icon": "ri-settings-3-line",
    "submenu": [
      {
        "label": "Finance Monitoring",
        "link": "/dashboard/finance",
        "icon": "ri-pie-chart-2-line"
      },
      {
        "label": "Sales Monitoring",
        "link": "/dashboard/sales",
        "icon": "ri-shopping-bag-3-line"
      },
      {
        "label": "Events Management",
        "link": "/dashboard/events",
        "icon": "ri-calendar-todo-line"
      },
      {
        "label": "Helpdesk Service",
        "link": "/dashboard/helpdesk",
        "icon": "ri-service-line"
      },
      {
        "label": "Product Management",
        "link": "/dashboard/product",
        "icon": "ri-suitcase-2-line"
      }
    ]
  }
];

export { dashboardMenu };