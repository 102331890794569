import React from 'react';
import { Card, Col, Row, Button, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import '../styles/DataSourceConnection.css';

export default function DataSourceConnection() {
  const dataSources = [
    { name: 'Postgres', icon: '🐘', description: 'Connect to your PostgreSQL database' },
    { name: 'Shopify', icon: '🛍️', description: 'Integrate with your Shopify store' },
    { name: 'Facebook Marketing', icon: '📊', description: 'Analyze your Facebook ad campaigns' },
    { name: 'Amazon Ads', icon: '🛒', description: 'Track your Amazon advertising performance' },
    { name: 'Amazon Seller Partner', icon: '🏪', description: 'Manage your Amazon seller account' },
    { name: 'Bing Ads', icon: '🔍', description: 'Monitor your Bing ad campaigns' },
    { name: 'File Upload', icon: '📁', description: 'Upload your own data files' },
    { name: 'Google Ads', icon: '📈', description: 'Analyze your Google Ads performance' },
    { name: 'Google Analytics', icon: '📊', description: 'Track your website analytics' },
  ];

  return (
    <div className="page-container">
      <Header />
      <div className="content-wrap">
        <Container fluid className="py-4 px-4">
          <Card className="card-overview mb-4">
            <Card.Body>
              <h1 className="card-title">Connect Your Data Sources</h1>
              <p className="card-text">Choose from the available data sources below to connect and start analyzing your data.</p>
            </Card.Body>
          </Card>

          <Nav variant="tabs" defaultActiveKey="all" className="mb-4">
            <Nav.Item>
              <Nav.Link eventKey="all">All Sources</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="connected">Connected</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="available">Available</Nav.Link>
            </Nav.Item>
          </Nav>

          <Row xs={1} md={2} lg={3} className="g-4">
            {dataSources.map((source, index) => (
              <Col key={index}>
                <Card className="h-100 card-data-source">
                  <Card.Body className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-3">
                      <div className="data-source-icon me-3">{source.icon}</div>
                      <Card.Title>{source.name}</Card.Title>
                    </div>
                    <Card.Text className="flex-grow-1">{source.description}</Card.Text>
                    <Button variant="primary" className="mt-3">Connect</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <Card className="card-request mt-4">
            <Card.Body className="text-center">
              <h5>Don't see your data source?</h5>
              <Link to="#" className="btn btn-outline-primary">Request a new integration</Link>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}