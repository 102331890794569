import React from "react";

// Dashboard
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import CampaignPerformance from "../dashboard/CampaignPerformance";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import ProductManagement from "../dashboard/ProductManagement";
import StrategyDashboard from "../pages/StrategyDashboard";
import AIRecommendations from "../pages/AIRecommendations";
import DataSourceConnection from "../pages/DataSourceConnection";
import AttributionOverview from "../pages/AttributionOverview";
import MarketingMixModeling from "../pages/MarketingMixModeling";
import LandingPage from "../pages/LandingPage";
import APIMonitoring from "../pages/APIMonitoring";

// ... (rest of the imports remain unchanged)

const protectedRoutes = [
  { path: "/", element: <LandingPage /> },
  { path: "dashboard/strategy", element: <StrategyDashboard /> },
  { path: "dashboard/finance", element: <FinanceMonitoring /> },
  { path: "dashboard/events", element: <EventManagement /> },
  { path: "dashboard/sales", element: <SalesMonitoring /> },
  { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
  { path: "dashboard/campaign-performance", element: <CampaignPerformance /> },
  { path: "dashboard/helpdesk", element: <HelpdeskService /> },
  { path: "dashboard/storage", element: <StorageManagement /> },
  { path: "dashboard/product", element: <ProductManagement /> },
  { path: "dashboard/ai-recommendations", element: <AIRecommendations /> },
  { path: "dashboard/data-sources", element: <DataSourceConnection /> },
  { path: "dashboard/attribution-overview", element: <AttributionOverview /> },
  { path: "dashboard/marketing-mix-modeling", element: <MarketingMixModeling /> },
  { path: "dashboard/api-monitoring", element: <APIMonitoring /> },
  // ... (rest of the routes remain unchanged)
]

export default protectedRoutes;