import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Row, Col, Nav, ListGroup, Table } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { dp1, dp2, dp3 } from "../data/DashboardData";
import { Doughnut } from 'react-chartjs-2';

export default function CampaignPerformance() {

  var chart = {
    parentHeightOffset: 0,
    toolbar: { show: false },
    stacked: true
  };

  var grid = {
    borderColor: 'rgba(72,94,144, 0.07)',
    padding: {
      top: -20,
      left: -20,
      right: -20,
      bottom: 0
    },
    yaxis: {
      lines: { show: false }
    }
  };

  var stroke = {
    curve: 'straight',
    width: 1.5
  };

  var fill = {
    type: 'gradient',
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    }
  };

  var yaxis = {
    min: 0,
    max: 80,
    show: false
  }

  function setOption(color, min, max) {
    return {
      chart: chart,
      colors: [color],
      grid: grid,
      stroke: stroke,
      xaxis: {
        type: 'numeric',
        tickAmount: 6,
        min: min,
        max: max,
        decimalsInFloat: 0,
        axisBorder: { show: false },
        labels: {
          style: {
            colors: '#6e7985',
            fontSize: '9px'
          }
        },
      },
      yaxis: yaxis,
      fill: fill,
      dataLabels: { enabled: false },
      legend: { show: false },
      tooltip: { enabled: false }
    };
  };

  const seriesOne = [{
    name: 'Impressions',
    data: dp3
  }, {
    name: 'Clicks',
    data: dp2
  }, {
    name: 'Conversions',
    data: dp1
  }];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: false }
    },
    colors: ['#0dcaf0', '#85b6fe', '#506fd9'],
    grid: {
      borderColor: 'rgba(72, 94, 144, .1)',
      padding: {
        top: -20
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 6,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    yaxis: {
      min: 0,
      max: 140,
      tickAmount: 6,
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  // Campaign Performance by Channel
  var dataPie = {
    labels: ['Social Media', 'Search', 'Display', 'Email', 'Affiliate', 'Other'],
    datasets: [{
      data: [25, 20, 18, 15, 13, 9],
      backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#0dcaf0', '#1c96e9', '#6e7985']
    }]
  };

  var optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  // Campaign ROI
  const seriesTwo = [{
    name: 'ROI',
    data: dp2
  }, {
    name: 'Cost',
    data: dp1
  }];

  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      sparkline: { enabled: true }
    },
    colors: ['#85b6ff', '#506fd9'],
    grid: {
      borderColor: 'rgba(72, 94, 144, .1)',
      padding: {
        top: -20,
        left: 0
      },
      yaxis: { lines: { show: false } }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      type: 'numeric',
      min: 5,
      max: 90,
      tickAmount: 6,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    yaxis: { max: 180 },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Campaign Performance</li>
            </ol>
            <h4 className="main-title mb-0">Campaign Performance Dashboard</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>

        <Row className="g-3">
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden">
                <h6 className="card-title text-dark mb-1">Total Impressions</h6>
                <h3 className="card-value mb-0">1,958,104</h3>
                <ReactApexChart
                  series={[{
                    data: dp3
                  }]}
                  options={setOption('#fd7e14', 5, 55)}
                  type="area"
                  height={80}
                  className="apex-chart-ten"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden">
                <h6 className="card-title text-dark mb-1">Total Clicks</h6>
                <h3 className="card-value mb-0">236,582</h3>
                <ReactApexChart
                  series={[{
                    data: dp3
                  }]}
                  options={setOption('#1c96e9', 45, 95)}
                  type="area"
                  height={80}
                  className="apex-chart-ten"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden">
                <h6 className="card-title text-dark mb-1">Conversions</h6>
                <h3 className="card-value mb-0">15,302</h3>
                <ReactApexChart
                  series={[{
                    data: dp3
                  }]}
                  options={setOption('#0cb785', 15, 75)}
                  type="area"
                  height={80}
                  className="apex-chart-ten"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden">
                <h6 className="card-title text-dark mb-1">Conversion Rate</h6>
                <h3 className="card-value mb-0">6.47%</h3>
                <ReactApexChart
                  series={[{
                    data: dp3
                  }]}
                  options={setOption('#506fd9', 35, 85)}
                  type="area"
                  height={80}
                  className="apex-chart-ten"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Campaign Performance Overview</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <ReactApexChart series={seriesOne} options={optionOne} type="area" height={290} className="apex-chart-nine mb-4" />

                <Row className="row-cols-auto gy-3 gx-5">
                  {[
                    {
                      "label": "Total Spend",
                      "value": "$145,208"
                    }, {
                      "label": "Cost per Click",
                      "value": "$0.61"
                    }, {
                      "label": "Cost per Conversion",
      "value": "$9.49"
                    }, {
                      "label": "ROAS",
                      "value": "3.21"
                    }, {
                      "label": "Avg. Order Value",
                      "value": "$78.40"
                    }
                  ].map((item, index) => (
                    <Col key={index}>
                      <label className="fs-xs mb-1 text-secondary">{item.label}</label>
                      <h4 className="ff-numerals text-dark mb-0">{item.value}</h4>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Top Performing Campaigns</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  {[
                    {
                      "name": "Summer Sale",
                      "conversions": "1,230",
                      "revenue": "$45,678"
                    }, {
                      "name": "Back to School",
                      "conversions": "980",
                      "revenue": "$32,456"
                    }, {
                      "name": "Holiday Special",
                      "conversions": "820",
                      "revenue": "$28,901"
                    }, {
                      "name": "Spring Collection",
                      "conversions": "750",
                      "revenue": "$24,678"
                    }, {
                      "name": "Loyalty Program",
                      "conversions": "690",
                      "revenue": "$21,345"
                    }
                  ].map((item, index) => (
                    <ListGroup.Item key={index} className="px-0 d-flex justify-content-between">
                      <div>
                        <h6 className="mb-1">{item.name}</h6>
                        <p className="mb-0 text-secondary">{item.conversions} conversions</p>
                      </div>
                      <div className="text-end">
                        <h6 className="mb-1 text-primary">{item.revenue}</h6>
                        <p className="mb-0 text-secondary">Revenue</p>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Performance by Channel</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="chart-donut-one mb-3">
                  <Doughnut data={dataPie} options={optionPie} />
                </div>

                <Table className="table-five">
                  <tbody>
                    {[
                      {
                        "dot": "primary",
                        "channel": "Social Media",
                        "conversions": "3,825",
                        "rate": "6.19%",
                        "success": true
                      }, {
                        "dot": "ui-02",
                        "channel": "Search",
                        "conversions": "3,060",
                        "rate": "4.52%",
                        "success": true
                      }, {
                        "dot": "teal",
                        "channel": "Display",
                        "conversions": "2,754",
                        "rate": "3.89%",
                        "success": false
                      }, {
                        "dot": "info",
                        "channel": "Email",
                        "conversions": "2,295",
                        "rate": "7.13%",
                        "success": true
                      }, {
                        "dot": "twitter",
                        "channel": "Affiliate",
                        "conversions": "1,989",
                        "rate": "5.48%",
                        "success": true
                      }, {
                        "dot": "gray-400",
                        "channel": "Other",
                        "conversions": "1,379",
                        "rate": "2.94%",
                        "success": false
                      }
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div><span className={"badge-dot bg-" + item.dot}></span> <span className="fw-medium">{item.channel}</span></div>
                        </td>
                        <td>{item.conversions}</td>
                        <td>
                          <div className={"justify-content-end text-" + (item.success ? "success" : "danger")}>{item.rate} <i className={"ri-arrow-" + (item.success ? "up" : "down") + "-line"}></i></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Campaign ROI</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="position-absolute p-1">
                  <label className="fw-medium fs-sm mb-1">Overall ROI</label>
                  <h3 className="card-value"><span className="text-success">321%</span></h3>
                </div>

                <ReactApexChart series={seriesTwo} options={optionTwo} type="area" height={195} className="apex-chart-ten mb-4" />

                <ListGroup as="ul" className="list-group-one">
                  {[
                    {
                      "name": "Social Media",
                      "roi": "412%",
                      "spend": "$45,230"
                    }, {
                      "name": "Search",
                      "roi": "385%",
                      "spend": "$38,900"
                    }, {
                      "name": "Display",
                      "roi": "274%",
                      "spend": "$28,620"
                    }, {
                      "name": "Email",
                      "roi": "296%",
                      "spend": "$17,940"
                    }, {
                      "name": "Affiliate",
                      "roi": "183%",
                      "spend": "$14,518"
                    }
                  ].map((item, index) => (
                    <ListGroup.Item key={index} as="li" className="px-0 d-flex align-items-center gap-2">
                      <div className="avatar bg-gray-300 text-secondary">{index + 1}</div>
                      <div>
                        <h6 className="mb-0">{item.name}</h6>
                        <small>ROI: {item.roi}</small>
                      </div>
                      <div className="ms-auto text-end">
                        <h6 className="ff-numerals mb-0 text-primary">{item.spend}</h6>
                        <small className="text-secondary">Spend</small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recent Campaign Activities</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {[
                  {
                    "title": "Summer Sale Campaign Launched",
                    "text": "New promotional campaign started with 20% discount on all summer products."
                  }, {
                    "title": "Email Newsletter Sent",
                    "text": "Weekly newsletter dispatched to 50,000 subscribers highlighting new arrivals."
                  }, {
                    "title": "Social Media Ad Performance",
                    "text": "Facebook ad campaign exceeded click-through rate expectations by 15%."
                  }
                ].map((item, index) => (
                  <div className="news-item" key={index}>
                    <div className="news-body">
                      <h6 className="news-title fw-semibold">
                        <Link to="" className="text-dark">{item.title}</Link>
                      </h6>
                      <p className="news-text mb-0">{item.text}</p>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col sm="12" xl="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Campaign Metrics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {[
                  {
                    "date": "Today, October 20",
                    "metrics": [
                      {
                        "color": "success",
                        "icon": "ri-arrow-up-line",
                        "title": "Impressions Increased",
                        "text": "24% increase from yesterday",
                        "value": "+45,000",
                        "percent": "+24%",
                        "success": true
                      }, {
                        "color": "primary",
                        "icon": "ri-arrow-up-line",
                        "title": "Click-through Rate",
                        "text": "2.5% increase from average",
                        "value": "3.8%",
                        "percent": "+2.5%",
                        "success": true
                      }, {
                        "color": "info",
                        "icon": "ri-arrow-down-line",
                        "title": "Cost per Click",
                        "text": "Decreased from last week",
                        "value": "$0.58",
                        "percent": "-5%",
                        "success": true
                      }
                    ]
                  }, {
                    "date": "Yesterday, October 19",
                    "metrics": [
                      {
                        "color": "success",
                        "icon": "ri-arrow-up-line",
                        "title": "Conversions",
                        "text": "Increase in product purchases",
                        "value": "+210",
                        "percent": "+15%",
                        "success": true
                      }
                    ]
                  }
                ].map((item, index) => (
                  <React.Fragment key={index}>
                    <div className={"divider divider-start fw-medium" + ((index === 0) ? " mt-0" : "")}>
                      <span>{item.date}</span>
                    </div>
                    <ListGroup as="ul" className="list-group-one">
                      {item.metrics.map((metric, ind) => (
                        <ListGroup.Item key={ind} as="li" className="px-0 d-flex align-items-center gap-2">
                          <div className={`avatar border border-2 border-${metric.color} text-${metric.color}`}><i className={metric.icon}></i></div>
                          <div>
                            <h6 className="mb-0">{metric.title}</h6>
                            <small>{metric.text}</small>
                          </div>
                          <div className="ms-auto text-end">
                            <h6 className="ff-numerals mb-0">{metric.value}</h6>
                            <small className={"text-" + (metric.success ? "success" : "danger")}>{metric.percent}</small>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </React.Fragment>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}